import 'whatwg-fetch'
import Cookies from "js-cookie";

const API_ORIGIN = process.env.API_ORIGIN
const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN

export default function fetchWrapper (url, data = {}) {
  return new Promise((resolve, reject) => {
    const token = Cookies.get("token")
    const refresh_token = Cookies.get("refreshtoken")
    const headers = new Headers({ 'Content-Type': 'application/json' })

    const method = data.method || 'GET'
    const mode = 'cors'
    let fullUrl
    if (url.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}`)) {
      fullUrl = url
      headers.append('Authorization', `Bearer ${refresh_token}`)
      headers.append('appkey', "2fa85n3v6fjqg37e1d0n04tl1h")
    } else if (url.startsWith('/')) {
      fullUrl = `${API_ORIGIN}${url}`
      if (token) headers.append('Authorization', `Token ${token}`)
    } else {
      fullUrl = `${API_ORIGIN}/production/${url}`
      if (token) headers.append('Authorization', `Token ${token}`)
    }

    return fetch(fullUrl, { 
      headers, method, mode, body: JSON.stringify(data.body)
    }).then(response => {
      if (response.status >= 400) {
        response.json().then(parsed => reject(parsed))
      } else {
        resolve(response.json())
      }
    })
  })
}
