import React from 'react'
import { connect } from 'react-redux'
import {
  Navbar,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse
} from 'reactstrap'
import {bindActionCreators} from "redux";
import {logoutUser} from "../actions/AuthActions";

function mapStateToProps (state) {
  return { email: state.auth.user.email }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({ logoutUser }, dispatch)
}

function LogoutButton (props) {
  return <span onClick={props.logoutUser}>Logout</span>
}

function ProdNav (props) {
  return (
    <Navbar color='light' light expand='md'>
      <NavbarBrand href='/'>Meural Production</NavbarBrand>
      <Collapse navbar>
        <Nav className='ml-auto' navbar>
          {
            props.email && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {props.email}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <LogoutButton logoutUser={props.logoutUser}/>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdNav)
