import React, { Component } from 'react'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Card
} from 'reactstrap'
import { connect } from 'react-redux'

const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN

function mapStateToProps (state) {
  return { loggedIn: state.auth.loggedIn }
}

class Auth extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.componentWillMount = this.componentWillMount.bind(this)
  }

  componentWillMount () {
    if (this.props.loggedIn) this.props.history.push('/')
  }

  handleSubmit (e) {
    e.preventDefault()
    window.location.replace(`${NETGEAR_ACCOUNT_ORIGIN}/login/meural?redirectUrl=${window.location.origin}`)
  }

  render () {
    return (
      <Row>
        <Col className='mt-3'>
          <Card body>
            <Form style={{textAlign:"center"}} onSubmit={this.handleSubmit}>
              <Button type="submit" disabled={this.state.loading}>
                Login
              </Button>
              <FormGroup className='mt-3'>
                <span>{this.state.loading ? 'Loading...' : ''}</span>
              </FormGroup>
            </Form>
          </Card>
        </Col>
        <Col />
        <Col />
      </Row>
    )
  }
}

export default connect(mapStateToProps, null)(Auth)
