import * as constants from '../constants/AuthConstants'
import * as API from '../api/Auth'
import Cookies from "js-cookie";

export const loginUserWithToken = () => dispatch => {
  return API.getUser().then(payload => dispatch({
    type: constants.RECEIVE_USER, payload
  })).catch(error => {
    dispatch({ type: constants.RECEIVE_AUTH_ERROR, error })
  })
}


export const logoutUser = () => dispatch => {
  const tokenParams = {
    domain: 'netgear.com',
    secure: true
  }
  Cookies.remove('token', tokenParams)
  Cookies.remove('refreshtoken', tokenParams)
  return dispatch({ type: constants.LOGOUT })
}
