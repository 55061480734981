import {getAccessToken} from "../api/onecloud";
import Cookies from "js-cookie";
import { logoutUser } from '../actions/AuthActions'
import { createHashHistory } from 'history'

const history = createHashHistory({forceRefresh:true})

function refreshToken(store, id) {
    const tokenParams = {
        domain: 'netgear.com',
        secure: true
    }
    getAccessToken().then(response => {
        const token = response.data.accessToken
        Cookies.set('token', token, tokenParams)
    }).catch(e => {
        if (e.meta && e.meta.code === 400) {
            store.dispatch(logoutUser())
            clearInterval(id)
            history.push('/login')
            window.location.reload()
        }
    })
}

export function updateAccessToken(store) {
    const refreshTime = Number(Cookies.get('exp')) ;
    const id = setInterval(() => refreshToken(store, id), 1000 * 60 * refreshTime - 2);
}
